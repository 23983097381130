/**
 * AnchorDestination
 */

import React from 'react';
import s from './AnchorDestination.module.scss';

const AnchorDestination = ({ hash }) => (
    <div className={s['AnchorDestination']} name={encodeURIComponent(hash)} id={encodeURIComponent(hash)} />
);

export default AnchorDestination;
